/* eslint-disable react/forbid-dom-props */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import CategoryItemsCount from 'Component/CategoryItemsCount';
import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import {
    CategoryFilterOverlay,
    CategoryPageComponent as SourceCategoryPage,
    CategorySort,
    FilterIcon,
    GridIcon,
    ListIcon,
} from 'SourceRoute/CategoryPage/CategoryPage.component';
import {
    CategoryPageLayout,
} from 'SourceRoute/CategoryPage/CategoryPage.config';
import { isCrawler, isSSR } from 'Util/Browser';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import CategoryProductList from '../../component/CategoryProductList';

import './CategoryPage.style';

export {
    CategoryFilterOverlay,
    FilterIcon,
    GridIcon,
    ListIcon,
    CategorySort,
};

/** @namespace Satisfly/Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPage {
    static propTypes = {
        ...super.propTypes,
        currentPage: PropTypes.string.isRequired,
    };

    renderCategoryProductList() {
        const {
            filter,
            search,
            selectedSort,
            selectedFilters,
            isMatchingListFilter,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            productListLoaderRef,
            category,
        } = this.props;

        const { activeLayoutType } = this.state;

        if (!this.displayProducts()) {
            setLoadedFlag();

            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="ProductListWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { this.renderItemsCount(true) }
                <CategoryProductList
                  filter={ filter }
                  search={ search }
                  sort={ selectedSort }
                  selectedFilters={ selectedFilters }
                  isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                  isMatchingListFilter={ isMatchingListFilter }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  layout={ activeLayoutType || CategoryPageLayout.GRID }
                  productListLoaderRef={ productListLoaderRef }
                  catName={ category?.name }
                  catId={ category?.id }
                />
            </div>
        );
    }

    renderSeoDescription() {
        const {
            category: {
                seo_description,
            },
            currentPage,
        } = this.props;

        if (!seo_description || currentPage > 1) {
            return null;
        }

        return (
            <div block="CategoryPage" elem="SeoWrapper">
                <div
                  block="CategoryPage"
                  elem="SeoDescription"
                >
                    <Html content={ seo_description } />
                </div>
            </div>
        );
    }

    renderFilterPlaceholder() {
        return (
            <div block="CategoryPage" elem="PlaceholderWrapper" />
        );
    }

    renderItemsCount(isVisibleOnMobile = false) {
        const { isMatchingListFilter, isMobile, isLoading } = this.props;

        if ((isVisibleOnMobile && !isMobile) || (!isVisibleOnMobile && isMobile)) {
            return null;
        }

        return (
            <CategoryItemsCount
              isMatchingListFilter={ isMatchingListFilter }
              isLoading={ isLoading }
            />
        );
    }

    renderMiscellaneous() {
        const { totalItems } = this.props;

        return (
            <aside block="CategoryPage" elem="Miscellaneous" mods={ { isNoItems: totalItems === 0 } }>
                { this.renderItemsCount() }
                <div
                  block="CategoryPage"
                  elem="MiscellaneousLayoutWrapper"
                >
                    { totalItems !== 0 && (
                        <div
                          block="CategoryPage"
                          elem="LayoutWrapper"
                          mods={ { isPrerendered: isSSR() || isCrawler() } }
                        >
                          { this.renderLayoutButtons() }
                          { this.renderCategorySort() }
                        </div>
                    ) }
                  <div
                    block="CategoryPage"
                    elem="LayoutWrapper"
                    mods={ { isPrerendered: isSSR() || isCrawler() } }
                  >
                      { this.renderFilterButton() }
                  </div>
                </div>
            </aside>
        );
    }

    renderContent() {
        return (
            <>
                { this.renderCategoryDetails() }
                { this.renderCmsBlock() }
                { this.renderFilterOverlay() }
                { this.renderMiscellaneous() }
                { this.renderCategoryProductList() }
                { this.renderSeoDescription() }
            </>
        );
    }

    render() {
        const hideProducts = !this.displayProducts();
        const { totalItems } = this.props;

        return (
            <main block="CategoryPage" mods={ { noResults: totalItems === 0 } }>
                <ContentWrapper
                  wrapperMix={ {
                      block: 'CategoryPage',
                      elem: 'Wrapper',
                      mods: { hideProducts, isNoProducts: totalItems === 0 },
                  } }
                  label="Category page"
                  isNotSection
                >
                    { this.renderContent() }
                </ContentWrapper>
            </main>
        );
    }
}

export default CategoryPageComponent;
